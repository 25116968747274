// Force vertical scrollbar (to avoid jumps on window resize)

html { overflow-y: scroll; }

// Remove text-shadow in selection highlight:
// https://twitter.com/miketaylr/status/12228805301
// (These selection rule sets have to be separate.)

::-moz-selection {
	color: $lightGray;
	background: $darkGray;
	text-shadow: none;
}

::selection {
	color: $lightGray;
	background: $darkGray;
	text-shadow: none;
}

// Remove the gap between audio, canvas, iframes, images, videos and the bottom of their containers
// https://github.com/h5bp/html5-boilerplate/issues/440
audio,
canvas,
iframe,
img,
svg,
video {
	vertical-align: middle;
}

// Remove default fieldset styles.
fieldset {
	border: 0;
	margin: 0;
	padding: 0;
}

// Allow only vertical resizing of textareas.
textarea {
	resize: vertical;
}


// ------ SDG base styles

* + * {
	margin-top: $defaultMargin;
}

*,
*:after,
*:before {
	box-sizing: border-box;
}

html {
	background-color: $darkCream;
	@include fontsize($normal-fontSize);
	@extend %sansSerif;
}

body {
	max-width: 1440px;
	color: $black;
	background-color: $bg;
	margin: 0 auto;
	box-shadow: 0 0 2em $darkGray;
	overflow-x: hidden;
	overflow-y: hidden;
}

div {
	position: relative;
}

a {
	color: currentColor;
}

i {
	font-style: normal;
}

button {
	border: none;
	background: transparent;
}

img {
	max-width: 100%;
	vertical-align: middle;
}
.img-stretch {
	width: 100%;
}

input[type="search"] {
	box-sizing: border-box;
}

* {
	/// transition: all $transitionTime ease;
}
a, a * {
	// transition: color $fastTransitionTime ease, background-color $fastTransitionTime ease;
}

// ------ Helpers

.clearfix, %clearfix {
	&:before,
	&:after {
		content: "";
		display: table;
	}
	&:after {
		clear: both;
	}

	zoom: 1; /* For IE 6/7 (trigger hasLayout) */
}

%hideText {
	text-indent: 100%;
	white-space: nowrap;
	overflow: hidden;
}

.mb, %mb { overflow:hidden; _overflow:visible; zoom:1; }
.mb_body, %mb_body { margin-top: 0; overflow:hidden; _overflow:visible; zoom:1; }
.mb .mb_media, %mb_media { float:left; margin-right: $widePadding; margin-top: 0; }
.mb .mb_media img { display:block; }
.mb .mb_media_alt, %mb_media_alt { float:right; margin-left: $widePadding; margin-top: 0; }

.hidden { display: none !important; visibility: hidden; }
.visuallyhidden { border: 0; clip: rect(0 0 0 0); height: 1px; margin: -1px; overflow: hidden; padding: 0; position: absolute; width: 1px; }
.invisible { visibility: hidden; }
.ir { display: block; border: 0; text-indent: -999em; overflow: hidden; background-color: transparent; background-repeat: no-repeat; text-align: left; direction: ltr; }
.ir br { display: none; }