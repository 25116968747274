// ------- MIXINS AND FUNCTIONS

@function rems($size) {
	@return $size / 16 * 1rem;
}

@mixin fontsize($size) {
	font-size: $size * 1px;
	font-size: rems($size);
}

//http://zerosixthree.se/8-sass-mixins-you-must-have-in-your-toolbox/
@mixin opacity($opacity) {
	opacity: $opacity;
	$opacity-ie: $opacity * 100;
	filter: alpha(opacity=$opacity-ie); //IE8
}

%serif, .serif {
	font-family: 'Roboto Slab', "Source Sans Pro", sans-serif;
	// letter-spacing: 0.5px;
}
%sansSerif, .sansSerif {
	font-family: 'Raleway', 'Source Sans Pro', sans-serif;
	// letter-spacing: 0px;
}

%antialiased {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}


// ------- MEDIA QUERIES

$screen: "only screen";
$smallVpw: 480;
$mediumThinVpw: 768;
$mediumVpw: 1086;

$smallNavVpw: 0;
$condensedNavVpw: 845;
$normalNavVpw: 1024;
$extendedNavVpw: 1410;

$smallScreenOnly: "#{$screen} and (max-width: #{$smallVpw + px})";
$mediumThinScreenOnly: "#{$screen} and (min-width: #{$smallVpw + 1 + px}) and (max-width: #{$mediumThinVpw + px})";
$mediumScreenOnly: "#{$screen} and (min-width: #{$mediumThinVpw + 1 + px}) and (max-width: #{$mediumVpw + px})";

$mediumThinScreen: "#{$screen} and (min-width: #{$smallVpw + 1 + px})";
$mediumScreen: "#{$screen} and (min-width: #{$mediumThinVpw + 1 + px})";
$largeScreen: "#{$screen} and (min-width: #{$mediumVpw + 1 + px})";

.only-small {
	display: none;

	@media #{$smallScreenOnly} {
		display: block;
	}
}

.only-medium {
	display: none;

	@media #{$mediumScreen} {
		display: block;
	}
}

// ------- TYPOGRAPHY

$base-fontSize: 18;

$hero-fontSize: 51;
$big-fontSize: 40;
$callout-fontSize: 29;
$normal-fontSize: 16;
$attention-fontSize: 19;
$small-fontSize: 14;

$bold-weight: 700;
$normal-weight: 400;
$light-weight: 300;


// ------- MARGINS

$tinyMargin: rems(4);
$smallMargin: rems(8);
$defaultMargin: rems(15);
$wideMargin: rems(24);
$tinyPadding: rems(8);
$smallPadding: rems(15);
$defaultPadding: rems(24);
$widePadding: rems(42);


// ------- MISC

$borderRadius: 5px;

$transitionTime: .5s;
$fastTransitionTime: .2s;