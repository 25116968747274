// ------ COLORS

$white: #ffffff;
$halfWhite: rgba(255, 255, 255, 0.5);
$black: #000000;
$red: #ff0000;
$lightbulbGreen: #5B5;
$bg: #222222;

$lightGray: #e8eaee;
$middleGray: #8f9ca8;
$darkGray: #222222;
$headerIconGray: #a7a9aC;

$lighterCream: #f5efea;
$lightCream: #d5cfca;
$middleCream: #b5afaa;
$darkCream: #857f7a;

$facebookBlue: #537bbd;
$twitterBlue: #78cdf0;
$rssOrange: #f39200;
$micGreen: #68af78;
$emailRed: #eb5755;


.white { color: $white; }
.bg-white { background-color: $white; }

.black { color: $black; }
.bg-black { background-color: $black; }

.lightGray { color: $lightGray; }
.bg-lightGray { background-color: $lightGray; }

.middleGray { color: $middleGray; }
.bg-middleGray { background-color: $middleGray; }


%bg-lightGray-gradient, .bg-lightGray-gradient {
	background: $white;
	background: -moz-linear-gradient(top, rgba($white,1) 30%, rgba($lightGray,1) 100%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(30%,rgba($white,1)), color-stop(100%,rgba($lightGray,1))); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top, rgba($white,1) 30%,rgba($lightGray,1) 100%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(top, rgba($white,1) 30%,rgba($lightGray,1) 100%); /* Opera 11.10+ */
	background: -ms-linear-gradient(top, rgba($white,1) 30%,rgba($lightGray,1) 100%); /* IE10+ */
	background: linear-gradient(to bottom, rgba($white,1) 30%,rgba($lightGray,1) 100%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$white', endColorstr='$lightGray',GradientType=0 ); /* IE6-9 */
}