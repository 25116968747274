h1,
h2,
h3,
h4,
h5,
h6, .serif {
  font-family: 'Roboto Slab', "Source Sans Pro", sans-serif; }

html, h5, .faux-h5, .altHeading, .sansSerif {
  font-family: 'Raleway', 'Source Sans Pro', sans-serif; }

.only-small {
  display: none; }
  @media only screen and (max-width: 480px) {
    .only-small {
      display: block; } }

.only-medium {
  display: none; }
  @media only screen and (min-width: 769px) {
    .only-medium {
      display: block; } }

.white {
  color: #ffffff; }

.bg-white {
  background-color: #ffffff; }

.black {
  color: #000000; }

.bg-black {
  background-color: #000000; }

.lightGray {
  color: #e8eaee; }

.bg-lightGray {
  background-color: #e8eaee; }

.middleGray {
  color: #8f9ca8; }

.bg-middleGray {
  background-color: #8f9ca8; }

.bg-lightGray-gradient {
  background: #ffffff;
  background: -moz-linear-gradient(top, white 30%, #e8eaee 100%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(30%, white), color-stop(100%, #e8eaee));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, white 30%, #e8eaee 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, white 30%, #e8eaee 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(top, white 30%, #e8eaee 100%);
  /* IE10+ */
  background: linear-gradient(to bottom, white 30%, #e8eaee 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$white', endColorstr='$lightGray',GradientType=0 );
  /* IE6-9 */ }

html {
  overflow-y: scroll; }

::-moz-selection {
  color: #e8eaee;
  background: #222222;
  text-shadow: none; }

::selection {
  color: #e8eaee;
  background: #222222;
  text-shadow: none; }

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle; }

fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

textarea {
  resize: vertical; }

* + * {
  margin-top: 0.9375rem; }

*,
*:after,
*:before {
  box-sizing: border-box; }

html {
  background-color: #857f7a;
  font-size: 16px;
  font-size: 1rem; }

body {
  max-width: 1440px;
  color: #000000;
  background-color: #222222;
  margin: 0 auto;
  box-shadow: 0 0 2em #222222;
  overflow-x: hidden;
  overflow-y: hidden; }

div {
  position: relative; }

a {
  color: currentColor; }

i {
  font-style: normal; }

button {
  border: none;
  background: transparent; }

img {
  max-width: 100%;
  vertical-align: middle; }

.img-stretch {
  width: 100%; }

input[type="search"] {
  box-sizing: border-box; }

.clearfix, .grid, form {
  zoom: 1;
  /* For IE 6/7 (trigger hasLayout) */ }
  .clearfix:before, .clearfix:after, .grid:before, form:before, .grid:after, form:after {
    content: "";
    display: table; }
  .clearfix:after, .grid:after, form:after {
    clear: both; }

.mb {
  overflow: hidden;
  _overflow: visible;
  zoom: 1; }

.mb_body {
  margin-top: 0;
  overflow: hidden;
  _overflow: visible;
  zoom: 1; }

.mb .mb_media {
  float: left;
  margin-right: 2.625rem;
  margin-top: 0; }

.mb .mb_media img {
  display: block; }

.mb .mb_media_alt {
  float: right;
  margin-left: 2.625rem;
  margin-top: 0; }

.hidden {
  display: none !important;
  visibility: hidden; }

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.invisible {
  visibility: hidden; }

.ir {
  display: block;
  border: 0;
  text-indent: -999em;
  overflow: hidden;
  background-color: transparent;
  background-repeat: no-repeat;
  text-align: left;
  direction: ltr; }

.ir br {
  display: none; }

.floatLeft, .grid-half, .grid-quarter, .grid-threeQuarters, .grid-oneThird, .grid-twoThirds, .grid-skinny, .grid-fat {
  float: left;
  display: block; }

.floatRight {
  float: right;
  display: block; }

.vSpace {
  margin-top: 1.5rem; }

.vSpace--big {
  margin-top: 2.625rem; }

.fencedWidth, .sectionContent {
  width: 90%;
  max-width: 1086px;
  margin-left: auto;
  margin-right: auto; }

.callout {
  padding: 2.625rem;
  width: 90%;
  max-width: 1086px;
  margin-left: auto;
  margin-right: auto; }

.calloutAction {
  position: absolute;
  bottom: -1rem;
  left: 0;
  right: 0;
  text-align: center; }

.contentWithSidebar {
  margin: 0;
  float: left; }
  @media only screen and (max-width: 480px) {
    .contentWithSidebar {
      width: 100%; } }
  @media only screen and (min-width: 769px) and (max-width: 1086px) {
    .contentWithSidebar {
      width: 66%; } }
  @media only screen and (min-width: 1087px) {
    .contentWithSidebar {
      width: 71%; } }

.sidebar {
  margin: 0;
  float: left; }
  @media only screen and (max-width: 480px) {
    .sidebar {
      width: 100%; } }
  @media only screen and (min-width: 769px) and (max-width: 1086px) {
    .sidebar {
      width: 33%; } }
  @media only screen and (min-width: 1087px) {
    .sidebar {
      width: 29%; } }

.sidebar--pull {
  float: right; }

.block {
  margin: 0 auto; }

.grid .pull {
  float: right;
  display: block; }

.grid-column, .grid-half, .grid-quarter, .grid-threeQuarters, .grid-oneThird, .grid-twoThirds, .grid-skinny, .grid-fat {
  float: left;
  margin-top: 0;
  margin-bottom: 0.9375rem;
  width: 100%; }

.grid--padded .grid-column, .grid--padded .grid-half, .grid--padded .grid-quarter, .grid--padded .grid-threeQuarters, .grid--padded .grid-oneThird, .grid--padded .grid-twoThirds, .grid--padded .grid-skinny, .grid--padded .grid-fat {
  padding: 0 2%; }

.grid .first {
  padding-left: 0;
  clear: left; }

.grid .last {
  padding-right: 0;
  clear: right; }

@media only screen and (max-width: 480px) {
  .grid-4up {
    float: left;
    margin-top: 0;
    margin-bottom: 0.9375rem;
    width: 100%;
    width: 47.5%;
    margin-right: 5%; }
    .grid-4up:nth-of-type(2n) {
      margin-right: 0; }
    .grid-4up:nth-of-type(2n+1) {
      clear: left; }
  .grid-5up {
    float: left;
    margin-top: 0;
    margin-bottom: 0.9375rem;
    width: 100%;
    width: 47.5%;
    margin-right: 5%; }
    .grid-5up:nth-of-type(2n) {
      margin-right: 0; }
    .grid-5up:nth-of-type(2n+1) {
      clear: left; }
  .grid-6up {
    float: left;
    margin-top: 0;
    margin-bottom: 0.9375rem;
    width: 100%;
    width: 47.5%;
    margin-right: 5%; }
    .grid-6up:nth-of-type(2n) {
      margin-right: 0; }
    .grid-6up:nth-of-type(2n+1) {
      clear: left; } }

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .grid-3up {
    float: left;
    margin-top: 0;
    margin-bottom: 0.9375rem;
    width: 100%;
    width: 47.5%;
    margin-right: 5%; }
    .grid-3up:nth-of-type(2n) {
      margin-right: 0; }
    .grid-3up:nth-of-type(2n+1) {
      clear: left; }
  .grid-4up {
    float: left;
    margin-top: 0;
    margin-bottom: 0.9375rem;
    width: 100%;
    width: 47.5%;
    margin-right: 5%; }
    .grid-4up:nth-of-type(2n) {
      margin-right: 0; }
    .grid-4up:nth-of-type(2n+1) {
      clear: left; }
  .grid-5up {
    float: left;
    margin-top: 0;
    margin-bottom: 0.9375rem;
    width: 100%;
    width: 47.5%;
    margin-right: 5%; }
    .grid-5up:nth-of-type(2n) {
      margin-right: 0; }
    .grid-5up:nth-of-type(2n+1) {
      clear: left; }
  .grid-6up {
    float: left;
    margin-top: 0;
    margin-bottom: 0.9375rem;
    width: 100%;
    width: 30%;
    margin-right: 5%; }
    .grid-6up:nth-of-type(3n) {
      margin-right: 0; }
    .grid-6up:nth-of-type(3n+1) {
      clear: left; } }

@media only screen and (min-width: 769px) and (max-width: 1086px) {
  .grid-2up {
    float: left;
    margin-top: 0;
    margin-bottom: 0.9375rem;
    width: 100%;
    width: 47.5%;
    margin-right: 5%; }
    .grid-2up:nth-of-type(2n) {
      margin-right: 0; }
    .grid-2up:nth-of-type(2n+1) {
      clear: left; }
  .grid-3up {
    float: left;
    margin-top: 0;
    margin-bottom: 0.9375rem;
    width: 100%;
    width: 30%;
    margin-right: 5%; }
    .grid-3up:nth-of-type(3n) {
      margin-right: 0; }
    .grid-3up:nth-of-type(3n+1) {
      clear: left; }
  .grid-4up {
    float: left;
    margin-top: 0;
    margin-bottom: 0.9375rem;
    width: 100%;
    width: 30%;
    margin-right: 5%; }
    .grid-4up:nth-of-type(3n) {
      margin-right: 0; }
    .grid-4up:nth-of-type(3n+1) {
      clear: left; }
  .grid-5up {
    float: left;
    margin-top: 0;
    margin-bottom: 0.9375rem;
    width: 100%;
    width: 30%;
    margin-right: 5%; }
    .grid-5up:nth-of-type(3n) {
      margin-right: 0; }
    .grid-5up:nth-of-type(3n+1) {
      clear: left; }
  .grid-6up {
    float: left;
    margin-top: 0;
    margin-bottom: 0.9375rem;
    width: 100%;
    width: 21.25%;
    margin-right: 5%; }
    .grid-6up:nth-of-type(4n) {
      margin-right: 0; }
    .grid-6up:nth-of-type(4n+1) {
      clear: left; }
  .dividedHalf {
    float: left;
    margin-top: 0;
    margin-bottom: 0.9375rem;
    width: 100%;
    width: 50%; }
    .dividedHalf:nth-of-type(2n) {
      margin-right: 0;
      border-right: 0;
      padding-left: 5%; }
    .dividedHalf:nth-of-type(2n+1) {
      margin-left: 0;
      border-right: 1px solid #ccc;
      clear: left;
      padding-right: 5%; } }

@media only screen and (min-width: 1087px) {
  .grid-2up {
    float: left;
    margin-top: 0;
    margin-bottom: 0.9375rem;
    width: 100%;
    width: 47.5%;
    margin-right: 5%; }
    .grid-2up:nth-of-type(2n) {
      margin-right: 0; }
    .grid-2up:nth-of-type(2n+1) {
      clear: left; }
  .grid-3up {
    float: left;
    margin-top: 0;
    margin-bottom: 0.9375rem;
    width: 100%;
    width: 30%;
    margin-right: 5%; }
    .grid-3up:nth-of-type(3n) {
      margin-right: 0; }
    .grid-3up:nth-of-type(3n+1) {
      clear: left; }
  .grid-4up {
    float: left;
    margin-top: 0;
    margin-bottom: 0.9375rem;
    width: 100%;
    width: 21.25%;
    margin-right: 5%; }
    .grid-4up:nth-of-type(4n) {
      margin-right: 0; }
    .grid-4up:nth-of-type(4n+1) {
      clear: left; }
  .grid-5up {
    float: left;
    margin-top: 0;
    margin-bottom: 0.9375rem;
    width: 100%;
    width: 16%;
    margin-right: 5%; }
    .grid-5up:nth-of-type(5n) {
      margin-right: 0; }
    .grid-5up:nth-of-type(5n+1) {
      clear: left; }
  .grid-6up {
    float: left;
    margin-top: 0;
    margin-bottom: 0.9375rem;
    width: 100%;
    width: 12.5%;
    margin-right: 5%; }
    .grid-6up:nth-of-type(6n) {
      margin-right: 0; }
    .grid-6up:nth-of-type(6n+1) {
      clear: left; }
  .dividedHalf {
    float: left;
    margin-top: 0;
    margin-bottom: 0.9375rem;
    width: 100%;
    width: 50%; }
    .dividedHalf:nth-of-type(2n) {
      margin-right: 0;
      border-right: 0;
      padding-left: 5%; }
    .dividedHalf:nth-of-type(2n+1) {
      margin-left: 0;
      border-right: 1px solid #ccc;
      clear: left;
      padding-right: 5%; } }

.grid-half {
  width: 50%; }

.grid-quarter {
  width: 25%; }

.grid-threeQuarters {
  width: 75%; }

.grid-oneThird {
  width: 33%; }

.grid-twoThirds {
  width: 66%; }

.grid-skinny {
  width: 20%; }

.grid-fat {
  width: 80%; }

.panelContainer {
  display: table;
  width: 100%; }

.panel {
  position: relative;
  display: block;
  background-size: cover;
  background-position: center;
  padding: 5%;
  margin: 0;
  vertical-align: middle; }

.panel--logo {
  text-align: center;
  padding: 5% 0 0; }

@media only screen and (min-width: 769px) {
  .panel {
    display: table-cell;
    width: 100%;
    height: 100%;
    padding: 3% 5%; }
  .panel--oneThird {
    width: 33.333%; }
  .panel--twoThirds {
    width: 66.667%; }
  .panel--logo {
    padding: 5% 1% 5% 5%; } }

.vExpand {
  min-height: 18em; }

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 25px;
  height: 0; }

.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

body {
  line-height: 1.7; }

.font-hero {
  font-size: 51px;
  font-size: 3.1875rem;
  line-height: 1.1; }

.font-big {
  font-size: 40px;
  font-size: 2.5rem;
  line-height: 1.1; }

body {
  font-size: 16px;
  font-size: 1rem; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  letter-spacing: 1px; }

h1, .faux-h1 {
  font-size: 51px;
  font-size: 3.1875rem;
  line-height: 1;
  margin-bottom: 1.5rem; }
  * + h1, * + .faux-h1 {
    margin-top: 1.5rem; }

h2, .faux-h2 {
  font-size: 40px;
  font-size: 2.5rem;
  line-height: 1.2;
  margin-bottom: 0.9375rem; }

h3, .faux-h3 {
  font-size: 29px;
  font-size: 1.8125rem;
  line-height: 1.3;
  margin-bottom: 0.9375rem; }

h4, .faux-h4 {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.3; }

h5, .faux-h5 {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.3;
  text-transform: uppercase; }

.altHeading {
  font-weight: 400; }

a {
  color: currentColor; }
  a:hover {
    text-decoration: none; }

h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
  text-decoration: none; }

em {
  font-style: italic; }

strong {
  font-weight: 700; }

.link--noDecoration, a.link--noDecoration, .button {
  text-decoration: none; }

.link--flipDecoration, a.link--flipDecoration {
  text-decoration: none; }
  .link--flipDecoration:hover, a.link--flipDecoration:hover {
    text-decoration: underline; }

.left {
  text-align: left; }

.center {
  text-align: center; }

.right {
  text-align: right; }

.uppercase {
  text-transform: uppercase; }

.tight {
  line-height: 1.2; }

p.meta {
  font-size: 14px;
  font-size: 0.875rem;
  font-style: italic; }

.attention {
  font-size: 19px;
  font-size: 1.1875rem;
  line-height: 1.3;
  font-weight: 700; }

input:not([type=radio]), textarea, select {
  display: inline-block;
  padding: 0.5rem 0.9375rem;
  background: transparent;
  border-radius: 5px;
  width: 100%; }

input[type=radio] {
  margin-right: 1em; }

input, textarea {
  background: rgba(255, 255, 255, 0.7);
  box-shadow: inset 0 0 7px rgba(0, 0, 0, 0.2); }

textarea.code {
  font-family: monospace; }

[type=submit] {
  width: auto; }

::-webkit-input-placeholder {
  color: #000000;
  color: rgba(0, 0, 0, 0.6); }

:-moz-placeholder {
  /* Firefox 18- */
  color: #000000;
  color: rgba(0, 0, 0, 0.6); }

::-moz-placeholder {
  /* Firefox 19+ */
  color: #000000;
  color: rgba(0, 0, 0, 0.6); }

:-ms-input-placeholder {
  color: #000000;
  color: rgba(0, 0, 0, 0.6); }

[type=submit], .submitButton {
  border: 1px solid transparent;
  font-weight: 700;
  text-align: center;
  background: transparent;
  cursor: pointer;
  float: right; }

.lightOnDark input, .lightOnDark textarea, .lightOnDark select {
  background: rgba(255, 255, 255, 0.7);
  box-shadow: inset 0 0 7px rgba(0, 0, 0, 0.1); }

.lightOnDark .submitButton, .lightOnDark [type=submit] {
  background: transparent;
  border-color: rgba(255, 255, 255, 0.7);
  color: #ffffff; }
  .lightOnDark .submitButton:hover, .lightOnDark [type=submit]:hover {
    background: rgba(255, 255, 255, 0.1); }

.form--full {
  clear: both; }

.form--half, .form--oneQuarter, .form--threeQuarters {
  float: left; }

.form--half {
  width: 48%;
  margin-right: 2%; }

.form--oneQuarter {
  width: 23%;
  margin-right: 2%; }

.form--threeQuarters {
  width: 73%;
  margin-right: 2%; }

@media only screen and (max-width: 480px) {
  .form--half {
    width: 100%;
    margin-right: 0; }
  .form--oneQuarter {
    width: 100%;
    margin-right: 0; }
  .form--threeQuarters {
    width: 100%;
    margin-right: 0; } }

/*! Flickity v1.0.0
http://flickity.metafizzy.co
---------------------------------------------- */
.flickity-enabled {
  position: relative; }

.flickity-enabled:focus {
  outline: none; }

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%; }

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%; }

/* draggable */
.flickity-enabled.is-draggable {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab; }

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing; }

/* ---- previous/next buttons ---- */
.flickity-prev-next-button {
  position: absolute;
  top: 50%;
  width: 44px;
  height: 44px;
  border: none;
  border-radius: 50%;
  background: white;
  background: rgba(255, 255, 255, 0.75);
  cursor: pointer;
  /* vertically center */
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%); }

.flickity-prev-next-button:hover {
  background: white; }

.flickity-prev-next-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #09F; }

.flickity-prev-next-button:active {
  filter: alpha(opacity=60);
  /* IE8 */
  opacity: 0.6; }

.flickity-prev-next-button.previous {
  left: 10px; }

.flickity-prev-next-button.next {
  right: 10px; }

/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px; }

.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px; }

.flickity-prev-next-button:disabled {
  filter: alpha(opacity=30);
  /* IE8 */
  opacity: 0.3;
  cursor: auto; }

.flickity-prev-next-button svg {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%; }

.flickity-prev-next-button .arrow {
  fill: #333; }

/* color & size if no SVG - IE8 and Android 2.3 */
.flickity-prev-next-button.no-svg {
  color: #333;
  font-size: 26px; }

/* ---- page dots ---- */
.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1; }

.flickity-rtl .flickity-page-dots {
  direction: rtl; }

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  filter: alpha(opacity=25);
  /* IE8 */
  opacity: 0.25;
  cursor: pointer; }

.flickity-page-dots .dot.is-selected {
  filter: alpha(opacity=100);
  /* IE8 */
  opacity: 1; }

.niceImage {
  display: block;
  max-width: 100%;
  padding: 10px;
  border: 1px solid rgba(143, 156, 168, 0.2); }

.campPhoto {
  display: inline-block;
  background: #b5afaa;
  padding: 15px;
  border: 1px solid black; }

.staffPhoto {
  margin: 1.5rem 0; }

.bodyContainer {
  color: #ffffff; }

.masthead {
  margin-bottom: 2em;
  padding: 4.2em;
  text-align: center;
  background-image: url("/assets/images/scene.png");
  background-position: center 70%;
  background-size: cover;
  border-bottom: 4.2px solid rgba(255, 255, 255, 0.2); }

.section {
  padding: 1.5rem 0; }

.sectionHeading {
  text-align: center;
  color: #b5afaa; }

h3 {
  color: #857f7a; }

.sectionSummary {
  max-width: 50em;
  text-align: center;
  margin: 0 auto; }

.sectionHeading:after {
  content: ' ';
  display: block;
  margin: 1em auto;
  width: 10%;
  height: 1px;
  border: none;
  border-bottom: 1px dotted rgba(255, 255, 255, 0.5); }

.gallery {
  background: transparent;
  margin: 3.9375rem auto 2.625rem; }

.gallery, .flickity-viewport, .flickity-slider {
  overflow: visible; }

.galleryCell {
  width: 30%;
  min-width: 20em;
  margin: 0; }

.tilt1, .galleryCell:nth-child(1) {
  transform: rotate(6deg); }

.tilt2, .galleryCell:nth-child(2) {
  transform: rotate(-5deg); }

.tilt3, .galleryCell:nth-child(3) {
  transform: rotate(7deg); }

.tilt4, .galleryCell:nth-child(4) {
  transform: rotate(-5deg); }

.tilt5, .galleryCell:nth-child(5) {
  transform: rotate(3deg); }

.tiltSlightLeft {
  transform: rotate(-2deg); }

.tiltSlightRight {
  transform: rotate(2deg); }

form {
  display: block;
  margin: 0 auto;
  max-width: 50em; }

.alert {
  font-weight: bold;
  color: gold; }

.button {
  display: inline-block;
  padding: .5em 1em;
  background: #ffffff;
  color: #000000;
  text-transform: uppercase;
  font-weight: 700; }

li {
  list-style: disc;
  list-style-position: outside;
  margin-left: 3em; }

.centeringContainer {
  text-align: center; }

.centeredBlock {
  text-align: left;
  display: inline-block; }
