// ------- FONT SIZES & WEIGHTS

body {
	line-height: 1.7;
}

.font-hero {
	@include fontsize($hero-fontSize);
	line-height: 1.1;
}

.font-big {
	@include fontsize($big-fontSize);
	line-height: 1.1;
}

// ------- TYPOGRAPHIC STYLES

body {
	@include fontsize($normal-fontSize);
}

h1,
h2,
h3,
h4,
h5,
h6 {
	@extend %serif;
	font-weight: $bold-weight;
	letter-spacing: 1px;
}

h1, .faux-h1 {
	@include fontsize($hero-fontSize);
	line-height: 1;
	margin-bottom: $defaultPadding;

	* + & {
		margin-top: $wideMargin;
	}

}

h2, .faux-h2 {
	@include fontsize($big-fontSize);
	line-height: 1.2;
	margin-bottom: $defaultMargin;
}

h3, .faux-h3 {
	@include fontsize($callout-fontSize);
	line-height: 1.3;
	margin-bottom: $defaultMargin;
}

h4, .faux-h4 {
	@include fontsize($normal-fontSize);
	line-height: 1.3;
}

h5, .faux-h5 {
	@extend %sansSerif;
	@include fontsize($normal-fontSize);
	line-height: 1.3;
	text-transform: uppercase;
}

.altHeading {
	@extend %sansSerif;
	font-weight: $normal-weight;
}

a {
	color: currentColor;

	&:hover {
		text-decoration: none;
	}
}

h1, h2, h3, h4, h5, h6 {
	& a {
		text-decoration: none;
	}
}

em {
	font-style: italic;
}

strong {
	font-weight: $bold-weight;
}

.link--noDecoration, a.link--noDecoration, %noDecoration  {
	text-decoration: none;
}

.link--flipDecoration, a.link--flipDecoration, %flipDecoration  {
	text-decoration: none;
	&:hover {
		text-decoration: underline;
	}
}

.left { text-align: left; }
.center { text-align: center; }
.right { text-align: right; }

.uppercase {
	text-transform: uppercase;
}

.tight {
	line-height: 1.2;
}

p.meta {
	@include fontsize($small-fontSize);
	font-style: italic;
}

.attention {
	@include fontsize($attention-fontSize);
	line-height: 1.3;
	font-weight: $bold-weight;
}