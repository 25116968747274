@import "vars";
@import "colors";

@import "base";
@import "layout";
@import "typography";
@import "forms";

@import "_flickity";


h1, h2, h3 {
	@extend %serif;
}

.niceImage {
	display: block;
	max-width: 100%;
	padding: 10px;
	border: 1px solid rgba($middleGray, 0.2);
}

.campPhoto {
	display: inline-block;
	background: $middleCream;
	padding: 15px;
	border: 1px solid black;
}

.staffPhoto {
	margin: $wideMargin 0;
}

.bodyContainer {
	color: $white;
}

.masthead {
	margin-bottom: 2em;
	padding: 4.2em;
	text-align: center;
	background-image: url('/assets/images/scene.png');
	background-position: center 70%;
	background-size: cover;
	border-bottom: 4.2px solid rgba(255, 255, 255, 0.2);
}

.section {
	padding: $defaultPadding 0;
}

.sectionHeading {
	text-align: center;
	color: $middleCream;
}

h3 {
	color: $darkCream;
}

.sectionSummary {
	max-width: 50em;
	text-align: center;
	margin: 0 auto;
}

.sectionHeading:after {
	content:' ';
	display: block;
	margin: 1em auto;
	width: 10%;
	height: 1px;
	border: none;
	border-bottom: 1px dotted $halfWhite;
}

.sectionContent {
	@extend %fencedWidth;
}

.gallery {
	background: transparent;
	margin: $widePadding*1.5 auto $widePadding;
}

.gallery, .flickity-viewport, .flickity-slider {
	overflow: visible;
}

.galleryCell {
	width: 30%;
	min-width: 20em;
	margin: 0;
}

.tilt1, .galleryCell:nth-child(1){
	transform: rotate(6deg);
}
.tilt2, .galleryCell:nth-child(2){
	transform: rotate(-5deg);
}
.tilt3, .galleryCell:nth-child(3){
	transform: rotate(7deg);
}
.tilt4, .galleryCell:nth-child(4){
	transform: rotate(-5deg);
}
.tilt5, .galleryCell:nth-child(5){
	transform: rotate(3deg);
}
.tiltSlightLeft {
	transform: rotate(-2deg);
}
.tiltSlightRight {
	transform: rotate(2deg);
}


form {
	display: block;
	margin: 0 auto;
	max-width: 50em;
}

.alert {
	font-weight: bold;
	color: gold;
}

.button {
	display: inline-block;
	padding: .5em 1em;
	background: $white;
	color: $black;
	@extend %noDecoration;
	text-transform: uppercase;
	font-weight: $bold-weight;
}

li {
	list-style: disc;
	list-style-position: outside;
	margin-left: 3em;
}


.centeringContainer {
	text-align: center;
}

.centeredBlock {
	text-align: left;
	display: inline-block;
}
