// ------ FLOATS

%floatContainer {}

@mixin floatLeft {
	float: left;
	display: block;
}
@mixin floatRight {
	float: right;
	display: block;
}

%floatLeft, .floatLeft {
	@include floatLeft;
}
%floatRight, .floatRight {
	@include floatRight;
}


// ------ SPACED

.vSpace {
	margin-top: $wideMargin;
}
.vSpace--big {
	margin-top: $widePadding;
}


// ------ FENCED WIDTH

@mixin fencedWidth {
	width: 90%;
	max-width: #{$mediumVpw + px};
	margin-left: auto;
	margin-right: auto;
}

.fencedWidth, %fencedWidth {
	@include fencedWidth;
}


.callout {
	padding: $widePadding;
	@include fencedWidth;
}

.calloutAction {
	position: absolute;
	bottom: -1rem;
	left: 0;
	right: 0;
	text-align: center;
}

//
// ------ SIDEBAR PAGES

.contentWithSidebar {
	margin: 0;
	float: left;
	@media #{$smallScreenOnly} {
		width: 100%;
	}
	@media #{$mediumScreenOnly} {
		width: 66%;
	}
	@media #{$largeScreen} {
		width: 71%;
	}
}

.sidebar {
	margin: 0;
	float: left;
	@media #{$smallScreenOnly} {
		width: 100%;
	}
	@media #{$mediumScreenOnly} {
		width: 33%;
	}
	@media #{$largeScreen} {
		width: 29%;
	}
}

.sidebar--pull {
	float: right;
}



// ------ BLOCKS

.block {
	margin: 0 auto;
}


// ------ GRID BASE

.grid {
	@extend %clearfix;
}

.grid .pull {
	@include floatRight;
}

@mixin grid-column {
	float: left;
	margin-top: 0;
	margin-bottom: $defaultMargin;//
	width: 100%;
}

.grid-column, %grid-column {
	@include grid-column;
}

.grid--padded .grid-column {
	padding: 0 2%;
}

.grid .first, %grid-first {
	padding-left: 0;
	clear: left;
}
.grid .last, %grid-last {
	padding-right: 0;
	clear: right;
}


// ------ GRID MIXINS

@mixin grid-2up($padding: default) {

	@include grid-column;

	@if $padding == none {
		width: 50%;
		margin-right: 0;
	}

	@if $padding == default {
		width: 47.5%;
		margin-right: 5%;
	}

	@if $padding == tight {
		width: 49%;
		margin-right: 2%;
	}

	&:nth-of-type(2n) {
		margin-right: 0;
	}
	&:nth-of-type(2n+1) {
		clear: left;
	}

}

@mixin grid-3up($padding: default) {

	@include grid-column;

	@if $padding == none {
		width: 33%;
		margin-right: 0;
	}

	@if $padding == default {
		width: 30%;
		margin-right: 5%;
	}

	@if $padding == tight {
		width: 32%;
		margin-right: 2%;
	}

	&:nth-of-type(3n) {
		margin-right: 0;
	}
	&:nth-of-type(3n+1) {
		clear: left;
	}

}

@mixin grid-4up($padding: default) {

	@include grid-column;

	@if $padding == none {
		width: 25%;
		margin-right: 0;
	}

	@if $padding == default {
		width: 21.25%;
		margin-right: 5%;
	}

	@if $padding == tight {
		width: 23%;
		margin-right: 2%;
	}

	&:nth-of-type(4n) {
		margin-right: 0;
	}
	&:nth-of-type(4n+1) {
		clear: left;
	}

}

@mixin grid-5up($padding: default) {

	@include grid-column;

	@if $padding == none {
		width: 20%;
		margin-right: 0;
	}

	@if $padding == default {
		width: 16%;
		margin-right: 5%;
	}

	@if $padding == tight {
		width: 18.4%;
		margin-right: 2%;
	}

	&:nth-of-type(5n) {
		margin-right: 0;
	}
	&:nth-of-type(5n+1) {
		clear: left;
	}

}

@mixin grid-6up($padding: default) {

	@include grid-column;

	@if $padding == none {
		width: 16.6%;
		margin-right: 0;
	}

	@if $padding == default {
		width: 12.5%;
		margin-right: 5%;
	}

	@if $padding == tight {
		width: 14.6%;
		margin-right: 2%;
	}

	&:nth-of-type(6n) {
		margin-right: 0;
	}
	&:nth-of-type(6n+1) {
		clear: left;
	}

}

@mixin dividedHalf($padding: default) {

	@include grid-column;

	width: 50%;

	&:nth-of-type(2n) {

		margin-right: 0;
		border-right: 0;

		@if $padding == none {
		}

		@if $padding == default {
			padding-left: 5%;
		}

		@if $padding == tight {
			padding-left: 2%;
		}

	}

	&:nth-of-type(2n+1) {

		margin-left: 0;
		border-right: 1px solid #ccc;
		clear: left;

		@if $padding == none {
		}

		@if $padding == default {
			padding-right: 5%;
		}

		@if $padding == tight {
			padding-right: 2%;
		}

	}

}


// ------ RESPONSIVE GRID



@media #{$smallScreenOnly} {
	.grid-2up {}
	.grid-3up {}
	.grid-4up {
		@include grid-2up();
	}
	.grid-5up {
		@include grid-2up();
	}
	.grid-6up {
		@include grid-2up();
	}
	.dividedHalf {}
}

@media #{$mediumThinScreenOnly} {
	.grid-2up {}
	.grid-3up {
		@include grid-2up();
	}
	.grid-4up {
		@include grid-2up();
	}
	.grid-5up {
		@include grid-2up();
	}
	.grid-6up {
		@include grid-3up();
	}
}

@media #{$mediumScreenOnly} {
	.grid-2up {
		@include grid-2up();
	}
	.grid-3up {
		@include grid-3up();
	}
	.grid-4up {
		@include grid-3up();
	}
	.grid-5up {
		@include grid-3up();
	}
	.grid-6up {
		@include grid-4up();
	}
	.dividedHalf {
		@include dividedHalf();
	}
}

@media #{$largeScreen} {
	.grid-2up {
		@include grid-2up();
	}
	.grid-3up {
		@include grid-3up();
	}
	.grid-4up {
		@include grid-4up();
	}
	.grid-5up {
		@include grid-5up();
	}
	.grid-6up {
		@include grid-6up();
	}
	.dividedHalf {
		@include dividedHalf();
	}
}




.grid-half {
	@extend .floatLeft;
	@extend .grid-column;
	width: 50%;
}

.grid-quarter {
	@extend .floatLeft;
	@extend .grid-column;
	width: 25%;
}
.grid-threeQuarters {
	@extend .floatLeft;
	@extend .grid-column;
	width: 75%;
}

.grid-oneThird {
	@extend .floatLeft;
	@extend .grid-column;
	width: 33%;
}
.grid-twoThirds {
	@extend .floatLeft;
	@extend .grid-column;
	width: 66%;
}

.grid-skinny {
	@extend .floatLeft;
	@extend .grid-column;
	width: 20%;
}
.grid-fat {
	@extend .floatLeft;
	@extend .grid-column;
	width: 80%;
}


// ------ PANELS

.panelContainer {
	display: table;
	width: 100%;
}

.panel {
	position: relative;
	display: block;
	background-size: cover;
	background-position: center;
	padding: 5%;
	margin: 0;
	vertical-align: middle;
}

.panel--logo {
	text-align: center;
	padding: 5% 0 0;
}

@media #{$mediumScreen} {

	.panel {
		display: table-cell;
		width: 100%;
		height: 100%;
		padding: 3% 5%;
	}

	.panel--oneThird {
		width: 33.333%;
	}

	.panel--twoThirds {
		width: 66.667%;
	}

	.panel--logo {
		padding: 5% 1% 5% 5%;
	}

}

.vExpand {
	min-height: 18em;
}

// ------ VIDEO BLOCK

.videoWrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
}
.videoWrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
