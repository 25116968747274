form {
	@extend %clearfix;
}

input:not([type=radio]), textarea, select {
	display: inline-block;
	padding: $tinyPadding $smallPadding;
	background: transparent;
	border-radius: $borderRadius;
	width: 100%;
}

input[type=radio] {
	margin-right: 1em;
}

input, textarea {
	background: rgba($white, 0.7);
	box-shadow: inset 0 0 7px rgba($black, 0.2);
}

textarea.code {
	font-family: monospace;
}

[type=submit] {
	width: auto;
}

::-webkit-input-placeholder {
	color: $black;
	color: rgba($black, 0.6);
}
:-moz-placeholder { /* Firefox 18- */
	color: $black;
	color: rgba($black, 0.6);
}
::-moz-placeholder {  /* Firefox 19+ */
	color: $black;
	color: rgba($black, 0.6);
}
:-ms-input-placeholder {
	color: $black;
	color: rgba($black, 0.6);
}


[type=submit], .submitButton {
	border: 1px solid transparent;
	font-weight: $bold-weight;
	text-align: center;
	background: transparent;
	cursor: pointer;
	float: right;
}

.lightOnDark {

	& input, & textarea, & select {
		background: rgba($white, 0.7);
		box-shadow: inset 0 0 7px rgba($black, 0.1);
	}

	.submitButton, [type=submit] {

		background: transparent;
		border-color: rgba($white, 0.7);
		color: $white;

		&:hover {
			background: rgba($white, 0.1);
		}

	}

}

.form--full {
	clear: both;
}

.form--half, .form--oneQuarter, .form--threeQuarters {
	float: left;
}

.form--half {
	width: 48%;
	margin-right: 2%;
}

.form--oneQuarter {
	width: 23%;
	margin-right: 2%;
}

.form--threeQuarters {
	width: 73%;
	margin-right: 2%;
}


@media #{$smallScreenOnly} {
	.form--half {
		width: 100%;
		margin-right: 0;
	}

	.form--oneQuarter {
		width: 100%;
		margin-right: 0;
	}

	.form--threeQuarters {
		width: 100%;
		margin-right: 0;
	}
}
